import React from "react";
import HashLoader from "react-spinners/HashLoader";
import axios from "axios";
import Image from "../image";

const ContactUs = ({ head1, head2, des1, des2, image }) => {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(false);
  const [errorMsg, setMsg] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const _handleChange = (e) => {
    setError(false);
    setSuccess(false);
    setEmail(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      setLoading(true);
      const today = new Date();
      const data = { Emails: email, Date: today.toLocaleDateString("en-US") };
      axios
        .post("https://leads.civsav.com/cbgwholesale", {
          data,
        })
        .then(() => {
          setEmail("");
          setLoading(false);
          setSuccess(true);
        })
        .catch(() => {
          setLoading(false);
          setMsg("Something went wrong!");
          setError(true);
        });
    } else {
      setMsg("Email Address Required!");
      setError(true);
    }
  };

  return (
    <section class="aboutColumns container">
      <div class="aboutColumns-content">
        <div class="aboutColumns-stack">
          <h2 class="aboutColumns-headline">{head1}</h2>
          <p
            class="aboutColumns-subheading"
            dangerouslySetInnerHTML={{ __html: des1 }}
          ></p>
        </div>
        <div class="aboutColumns-stack">
          <h2 class="aboutColumns-headline">{head2}</h2>
          <p class="aboutColumns-subheading">{des2}</p>
          <div
            class="newsletter -large validate"
            style={{ position: "relative" }}
          >
            <div id="aboutColumns_mc_embed_signup_scroll">
              <div class="mc-field-group">
                <label htmlFor="aboutColumns_mce-EMAIL" class="_hidden">
                  Email Address
                </label>
                <input
                  value={email}
                  onChange={_handleChange}
                  type="email"
                  name="email"
                  class="newsletter-input -large required email"
                  id="aboutColumns_mce-EMAIL"
                  font-size="18.5px !important"
                  placeholder="Contact Us To Audit Your Website"
                />
              </div>
              <div id="aboutColumns_mce-responses" class="clear">
                <div
                  class="response"
                  id="aboutColumns_mce-error-response"
                  style={{ display: "none" }}
                ></div>
                <div
                  class="response"
                  id="aboutColumns_mce-success-response"
                  style={{ display: "none" }}
                ></div>
              </div>
              <div
                style={{ position: "absolute", left: "-5000px" }}
                aria-hidden="true"
              ></div>

              <button
                class="newsletter-submit submit-btn"
                type="submit"
                onClick={handleSubmit}
                id="aboutColumns_mc-embedded-subscribe"
                disabled={loading}
              >
                <span class="_hidden">Subscribe</span>
                {loading ? (
                  <HashLoader size={20} color={"#D5B795"} />
                ) : (
                  <svg
                    width="16"
                    height="13"
                    viewBox="0 0 16 13"
                    fill="#BBBBBE"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6.81016 12.8243H8.89016L15.1622 6.55227L8.89016 0.280273H6.81016L12.3302 5.78427H0.410156V7.32027H12.3302L6.81016 12.8243Z"></path>
                  </svg>
                )}
              </button>
              <div style={{ marginTop: 10 }}>
                {error && (
                  <label htmlFor="message" className="_error">
                    {errorMsg}
                  </label>
                )}
                {success && (
                  <label htmlFor="message" className="_success">
                    Successfully Subscribed!
                  </label>
                )}
              </div>
            </div>
          </div>
          <p></p>
        </div>
      </div>
      <div class="aboutColumns-image rm-mobile" style={{ maxHeight: 350 }}>
        <Image
          src={image}
          imgClassName="aboutColumns-image"
          placeholder="blurred"
        />
      </div>
    </section>
  );
};

export default ContactUs;
