import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import Image from "../components/image";
import Header from "../components/header";
import Footer from "../components/footer";
import CBGFlowerImg from "../assets/images/cbgFlower.png";

import ContactUs from "../components/contactUs";

const Strategy = ({ location }) => {
  return (
    <Fragment>
      <Helmet>
        <title>CBG Flower Wholesale</title>
        <meta
          name="description"
          content="CBG Wholesalers offers you the best CBG flower wholesale. We are the go-to desitnation for premium bulk CBG flower. Visit our website to learn more."
        />
        <link
          href="https://cbgwholesalers.com/cbg-flower-wholesale"
          rel="canonical"
        />
      </Helmet>
      <main>
        <Header pathname={location.pathname} />
        <ToastContainer />
        <section class="hero -gold">
          <div
            class="hero-stack container"
            style={{
              backgroundImage: `url(${CBGFlowerImg})`,
            }}
          >
            <h1
              class="hero-headline -animatedLine"
              style={{ maxWidth: "365px" }}
            >
              CBG Flower
              <br />
              {/* <span>Ranking #1 in Organic search</span> */}
            </h1>
            <Image
              src={"cbgFlower.png"}
              className="hero-innerImage-outer"
              imgClassName="hero-innerImage"
              placeholder="blurred"
            />
            <p
              class="hero-subheading -mt-15"
              style={{ maxWidth: "40ch", marginBottom: "-5rem" }}
            >
              Add CBG to your product lineup with our huge selection of CBG
              flower available for purchase in bulk. CBG Wholesalers is the
              go-to destination for premium bulk CBG flower. Vetted for safety,
              efficacy, and quality, we only offer products from brands we
              trust. We make it easy to offer your customers safe, affordable
              CBG products they will love.
            </p>
          </div>
        </section>

        <ContactUs
          // head1="First Impressions Matter"
          head3="Optimize Your Digital Footprint"
          des1="CBG flower is in high demand from users seeking relief from pain, nausea, and inflammation. CBG alleviates these symptoms by bonding with receptors along the nervous system. After smoking or vaping CBG flower from our premium selection, your customers will be highly impressed with its quality and efficacy. Because CBG is not psychoactive, your customers will love getting potent relief from hemp without the uncomfortable high, paranoia, or couchlock. <br /><br />Choose a knowledgeable CBG wholesaler so your customers can access the safe, high-quality products they deserve. Quit worrying about managing bulk orders across multiple wholesalers. We carry products from brands you already know and love, making it simple to get all of the bulk CBG flower you need in one easy order. With our deep knowledge of the CBG flower we offer, we stand by its quality and efficacy. Pick a flexible wholesale partner like CBG Wholesalers that will support your business in any stage."
          des2="For the widest selection of potent CBG flower from the top brands, you have to choose CBG Wholesalers. With the highest-quality CBG flower at the best price points, our selection is unmatched. Get your CBG flower from the most reputable wholesaler online, because your customers deserve the best quality and prices."
          // head2="Be 2022 Ready"
          image="sub-banner.png"
        />
        <Footer isTopborder={true} />
      </main>
    </Fragment>
  );
};

export default Strategy;
